import { type CSSObject } from "@emotion/react";

interface ApplyPlatformAwareInteractionStylesProps {
  hoverStyle: CSSObject;
  /**
   * Some of the MUI components have default hover styles which we want to override.
   * Example: IconButton of primary color has a transparent background set by default.
   * On platforms that don't support hover, we want to preserve original styles
   * and that's why this function exists.
   */
  defaultStyle?: CSSObject;
}

/**
 * Embeds logic for adding hover and focused state on platforms that support it (so we avoid e.g sticky hover state on mobile)
 * It also supports forcing visual active state by setting aria-selected to true
 *
 * @example
 * sx={theme => ({
 *   ...getHoverActiveCssStateSelectors({
 *     backgroundColor: theme.palette.primary,
 *   }),
 * })}
 *
 */
export const applyPlatformAwareInteractionStyles = (
  props: ApplyPlatformAwareInteractionStylesProps
) => {
  const { hoverStyle, defaultStyle } = props;
  return {
    "@media (hover: hover)": {
      "&:hover, &[aria-selected=true]": {
        ...hoverStyle,
      },
    },

    "@media (hover: none)": {
      "&:hover, &:focus": {
        ...defaultStyle,
      },

      "&:active, &[aria-selected=true]": {
        ...hoverStyle,
      },
    },
  };
};
